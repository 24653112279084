import React from 'react'
import { useFormik } from 'formik'
import { useStoreon } from 'storeon/react'

import ChangePassword from '../../../views/BaseModal/ModalContent/ChangePassword'

import useTranslate from '../../../hooks/useTranslate'
import usePage from '../../../hooks/usePage'
import { useSocket } from '../../../apps/hooks'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'

import { userApiService } from '../../../api/services'
import api from '../../../api'

import { PasswordSchema } from '../../../utils/ValidateSchemes'
import { convertError } from '../../../utils'

import { runtimeConfig } from '../../../config'

const MAIN_URL_WSS = runtimeConfig.API_URL_WSS
const ChangePasswordModal = ({ handleClose, isUnclosable, setUnclosable }) => {
  const { dispatch } = useStoreon()
  const { openModal, contentTypes } = useModalStore()
  const { init, update, socket } = useSocket()
  const errorModalContent = useModalIntlContent(contentTypes.warning)
  const { page } = usePage()
  const passwordConfig = page.init_state.global?.password_config
  
  const { translate } = useTranslate()

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      password2: ''
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        const { access_token: token } = await userApiService.setPassword(values)
        dispatch('user/get', {})

        socket.close()
        const initSocket = init(MAIN_URL_WSS, token)
        update(initSocket)

        setUnclosable(false)
        handleClose()
      } catch (e) {
        if (e.response) {
          const errors = e.response?.data
          setErrors(errors)
        } else {
          const error = convertError(e)
          openModal('commonConfirm', { ...errorModalContent, description: translate(error) })
        }
      }
    },
    validationSchema: () => PasswordSchema(passwordConfig)
  })

  return (
    <ChangePassword handleClose={handleClose} formik={formik} isUnclosable={isUnclosable} />
  )
}

export default ChangePasswordModal

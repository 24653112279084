/* eslint-disable camelcase */

import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import dayjs from './dayjs'
import { INTL_LABELS, LABELS_PROJECT_SPACE, REPORT_FORMATS, REPORT_TYPES } from '../const'
import qs from 'query-string'
import he from 'he'
import { calculationApiService } from '../api/services'

export { dayjs }

export const overwritingEventForPinchZoom = (fn) => {
  const customEventObj = {}
  return (e) => {
    if (e === 'ZOOMING_IN' || e === 'ZOOMING_OUT') {
      customEventObj.deltaY = e === 'ZOOMING_IN' ? -1 : 1
      return fn(customEventObj)
    }
    return fn(e)
  }
}

export const getNumericDateOpt = (options = {}) => {
  return {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options
  }
}

export const stopProp = (e) => e.stopPropagation()
export const hasSomeObjKeys = (obj, obj2) => {
  if (!_.isObject(obj) || !_.isObject(obj2)) return false
  return Object.keys(obj).some(r => Object.keys(obj2).includes(r))
}

export const filterOjectByKey = (obj, key) => {
  return Object.keys(obj)
    .filter(el => el !== key)
    .reduce((init, el) => {
      init[el] = obj[el]
      return init
    }, {})
}

export const ConvertStringToHTML = (str) => {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser()
  const doc = parser.parseFromString(str, 'text/html')
  return doc.body
}

export const compose =
    (...fns) =>
      (x) =>
        fns.reduceRight((res, fn) => fn(res), x)

export const cloneDeep = (obj) => {
  if (obj == null || typeof obj !== 'object') return obj
  if (obj instanceof Date) {
    const copy = new Date()
    copy.setTime(obj.getTime())
    return copy
  }
  if (obj instanceof Array) {
    const copy = []
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = cloneDeep(obj[i])
    }
    return copy
  }
  if (obj instanceof Object) {
    const copy = {}
    for (const i in obj) {
      if (obj[i] != null && typeof obj[i] === 'object') {
        copy[i] = cloneDeep(obj[i])
      } else {
        copy[i] = obj[i]
      }
    }
    return copy
  }
}
/**
 * * метод для форматирования данных, нужных для отрисовки сцены:
 * * грузы, ГП, настройки для пакинга и тд...
 * @param {Array<Object>} groups         выбранные группы с грузами
 * @param {Array<Object>} cargoSpaces    выбранные ГП
 * @param {number}        projectID      id проекта
 * @param {boolean}       userSort       флаг, для алгоритма учитывающий очередность загрузки
 * @param {Object | null} debugSettings  обьект настроек, для алгоритма
 */

export const formatPacker = ({
  groups = [],
  cargoSpaces = [],
  projectID = 0,
  userSort = false,
  debugSettings = null
}) => {
  let palletId
  let sort
  /**
   * @type {Array<number>} - id выбранных ГП
   */
  const spaces = cargoSpaces.filter(space => space.id).map(space => space.id)
  /**
   * @type {Array<Object>} - Форматированные грузы
   */
  let packerGroups = groups.map((item, index) => {
    const { cargoes, pallet, group_id, title } = item
    palletId = pallet?.id
    sort = index + 1
    let boxes = cargoes.map((el) => {
      if (el.width && el.length && el.height) {
        return {
          length: el.length,
          width: el.width,
          height: el.height,
          mass: Number(el.mass),
          stacking: Boolean(el.stacking),
          stacking_limit: el.stacking_limit ? Number(el.stacking_limit) : 0,
          turnover: Boolean(el.turnover),
          is_rotate_y: el.is_rotate_y,
          margin_length: el.margin_length,
          margin_width: el.margin_width,
          // overhang: el.overhang,
          count: Number(el.count),
          type: el.type,
          article: el.article,
          cargo_id: el.cargo_id,
          color: el.color,
          title: el.title ? el.title : el.defaultTitle
        }
      } else return null
    })
    boxes = boxes.filter((item) => item)

    if (boxes.length > 0) {
      return {
        pallet: !palletId ? null : palletId,
        group_id: group_id,
        cargoes: boxes,
        title: title,
        sort: sort
      }
    }
    return null
  })

  packerGroups = packerGroups.filter((item) => item)

  const data = {
    project: projectID,
    input_data: {
      cargo_spaces: spaces,
      groups: packerGroups,
      userSort
    }
  }

  if (debugSettings) {
    const debug = {
      ...debugSettings,
      box_intersection: +debugSettings.box_intersection,
      expand_pallet: {
        plus_x: +debugSettings.expand_pallet.plus_x,
        minus_x: +debugSettings.expand_pallet.minus_x,
        plus_z: +debugSettings.expand_pallet.plus_z,
        minus_z: +debugSettings.expand_pallet.minus_z
      },
      box_expand_fit: +debugSettings.box_expand_fit
    }
    data.input_data.debug_settings = debug
  }

  return data
}

export const reportProjectMessage = (format) =>
  `Возникла ошибка при генерации ${format} отчета`

export const replaceSymbols = (value) =>
  value.replace(/[+~#$^*_{}"\\/:[\]'`<>|]/g, '')

export const decodeHTML = (rawHTML) => he.decode(rawHTML)

export const convertError = (e) => {
  if (e.response) {
    if (Array.isArray(e.response.data)) {
      return e.response.data.join(', ')
    } else {
      return Object.values(e.response.data).join(', ')
    }
  } else {
    return 'Ошибка сервера'
  }
}

export const serializeFullReport = ({ excluding_pallet: excluding, ...full }) => {
  const report = [
    {
      title: {
        id: 'excelWithoutPallets',
        defaultMessage: 'Excel без палет'
      },
      fileType: REPORT_FORMATS.EXCEL,
      reportType: REPORT_TYPES.EXCLUDING,
      status: excluding?.status_excel || null,
      link: excluding?.link_excel || null,
      create: calculationApiService.createReportExcludingPallets,
      show: !!excluding
    },
    {
      title: {
        id: 'pdfWithoutPallets',
        defaultMessage: 'PDF без палет'
      },
      fileType: REPORT_FORMATS.PDF,
      reportType: REPORT_TYPES.EXCLUDING,
      status: excluding?.status || null,
      link: excluding?.link || null,
      startDate: excluding?.start_date || null,
      completionDate: excluding?.completion_date || null,
      create: calculationApiService.createReportExcludingPallets,
      show: !!excluding
    },
    {
      title: 'Excel',
      fileType: REPORT_FORMATS.EXCEL,
      reportType: REPORT_TYPES.FULL,
      status: full.status_excel,
      link: full.link_excel,
      create: calculationApiService.createFullPivotReport,
      show: true
    },
    {
      title: 'PDF',
      fileType: REPORT_FORMATS.PDF,
      reportType: REPORT_TYPES.FULL,
      status: full.status,
      link: full.link,
      startDate: full.start_date,
      completionDate: full.completion_date,
      create: calculationApiService.createFullPivotReport,
      show: true
    }
  ]
  return report
}

export const downloadFile = (url) => {
  const link_url = document.createElement('a')
  link_url.download = url.substring(url.lastIndexOf('/') + 1, url.length)
  link_url.href = url
  document.body.appendChild(link_url)
  link_url.setAttribute('target', '_blank')
  link_url.click()
  document.body.removeChild(link_url)
}

export const openUrl = (url) => window.open(url, '_blank')

export const stopEvent = e => {
  e.stopPropagation()
  e.preventDefault()
}

export const formatRelativeDate = (from, to, locale = 'ru') => from.locale(locale).to(to)

export const getMoscowTime = () => dayjs().tz('Europe/Moscow')

export const getCustomCargoSpaceTitle = (type) => INTL_LABELS[type]

export const getLabelsProjectSpacesTitle = (type) => LABELS_PROJECT_SPACE[type]

export function useDocumentTitle (title) {
  useEffect(() => {
    document.title = title
  }, [title])
}

export const getQueryString = (params) => {
  return `?${qs.stringify(params)}`
}

export const createQueryURL = (path, params) => {
  return params ? `${path}?${qs.stringify(params)}` : path
}

export function uuidv4 () {
  const uuid = new Array(36)
  for (let i = 0; i < 36; i++) {
    uuid[i] = Math.floor(Math.random() * 16)
  }
  uuid[14] = 4 // set bits 12-15 of time-high-and-version to 0100
  uuid[19] = uuid[19] &= ~(1 << 2) // set bit 6 of clock-seq-and-reserved to zero
  uuid[19] = uuid[19] |= 1 << 3 // set bit 7 of clock-seq-and-reserved to one
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
  return uuid.map((x) => x.toString(16)).join('')
}

export function useComponentVisible (initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

export function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const formattingDate = (date) => {
  if (!dayjs(date).isValid()) {
    return date
  }
  const newDate = dayjs(date).format('DD.MM.YYYY')
  return newDate
}

export const dateHistory = (date) => {
  if (!dayjs(date).isValid()) {
    return date
  }
  const newDate = dayjs(date).format('DD.MM.YYYY HH:mm')
  return newDate
}

export const range = (size, startAt = 1) => {
  return [...Array(size).keys()].map((i) => i + startAt)
}

export const getPathForWorkspace = (type, id, path, spaceType) => {
  const ref = {
    project: (id) => `project/${id}`,
    cargo: (id, path) => `${path}/update/${id}`,
    cargo_space: (id, path, type) => `${path}/update/${type}/${id}`
  }

  return ref[type](id, path, spaceType)
}

export const createCargo = (cargo) => {
  const result = {}
  const indentation = ['indentation_l', 'indentation_w']
  for (const [key, value] of Object.entries(cargo)) {
    if (indentation.includes(key)) {
      result[key] = +value
      continue
    }
    result[key] = value
  }
  // result[CARGO_VALUES.overhang_angle] = cargo.overhang_angle ? +cargo.overhang_angle_value : 0
  return result
}

export const createErrorObj = (errorObj, indexGroup, indexCargo) => {
  const result = {}
  for (const [k] of Object.entries(errorObj)) {
    if (['length', 'width', 'height'].includes(k)) {
      if (!result?.size) {
        result.size = []
      }
      switch (k) {
        case 'length':
          result.size[0] = true
          break
        case 'width':
          result.size[2] = true
          break
        case 'height':
          result.size[1] = true
          break
        default:
          break
      }
      continue
    }
    result[k] = true
  }
  const groups = []
  const cargo_groups = []
  cargo_groups[indexCargo] = result
  groups[indexGroup] = { cargo_groups }
  return groups
}

export const hexValidator = (value) => {
  return /^#([0-9a-f]{3}){1,2}$/i.test(value)
}

export const clearThree = (obj) => {
  while (obj.children.length > 0) {
    clearThree(obj.children[0])
    obj.remove(obj.children[0])
  }
  if (obj.geometry) {
    obj.geometry.dispose()
    obj.geometry = undefined
  }

  if (obj.material) {
    Object.keys(obj.material).forEach((prop) => {
      if (!obj.material[prop]) {
        return
      }
      if (
        obj.material[prop] !== null &&
        typeof obj.material[prop].dispose === 'function'
      ) {
        obj.material[prop].dispose()
      }
    })
    obj.material.dispose()
    obj.material = undefined
  }
}

export const formattingPrice = (value) => {
  value = new Intl.NumberFormat('ru-RU').format(value)
  const indexComma = value.indexOf(',')

  return indexComma > 0 ? value.slice(0, indexComma) : value
}

export const formattingPhone = (phone) => {
  const numbresRegExp = /\d/g
  const phoneRegExp = /[+()-]/g
  if (!phone) {
    return ''
  } else {
    let formattedPhone = ''
    const mathPhone = phone.match(numbresRegExp)
    const hrefPhone = '+' + mathPhone.join('')

    if (phone.match(phoneRegExp)) {
      formattedPhone = phone
      return [hrefPhone, formattedPhone]
    } else {
      phone = phone.match(numbresRegExp).join('')

      for (let i = 0; i < phone.length; i++) {
        if ([1, 4, 7, 9].includes(i)) {
          formattedPhone += '-'
        }
        formattedPhone += phone[i]
      }

      return [hrefPhone, formattedPhone]
    }
  }
}
export const cleanPhone = (phone) => {
  const phoneRegExp = /[+()-\s]/g
  return phone.replace(phoneRegExp, '')
}

export function setCookie (name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
export function getCookie (name) {
  try {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length) }
    }
    return null
  } catch (e) {
    return null
  }
}

export function removeCookie (name) {
  return setCookie(name, '', -999)
}

export const decOfNum = (number, titles) => {
  const decCache = []
  const decCases = [2, 0, 1, 1, 1, 2]
  if (!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)]
  return titles[decCache[number]]
}

export const decOfMonth = (number) => {
  return decOfNum(number, ['месяц', 'месяца', 'месяцев'])
}
export const decOfDay = (number) => {
  return decOfNum(number, ['день', 'дня', 'дней'])
}
export const decOfHour = (number) => {
  return decOfNum(number, ['час', 'часа', 'часов'])
}

export const formatPrice = (value, hasCurrencySign = true) => {
  return new Intl.NumberFormat('ru-RU', {
    style: `${hasCurrencySign ? 'currency' : 'decimal'}`,
    currency: 'RUB',
    minimumFractionDigits: 0
  })
    .format(value)
}

export const getChangedFields = (defObj, changedObj) => {
  const onlyChangedObj = {}
  const unionKeys = _.union(_.keys(defObj), _.keys(changedObj))
  const changedKeys = unionKeys.filter((key) => defObj[key] !== changedObj[key])
  changedKeys.forEach((el) => { onlyChangedObj[el] = changedObj[el] })
  return onlyChangedObj
}

/**
   * обработчик touch event для зума фото
   * @param {touch event} e
   */
export const zoomPhoto = (e) => {
  const zoomer = e.currentTarget
  const offsetX = e.touches[0].clientX
  const offsetY = e.touches[0].clientY

  const x = (offsetX / zoomer.offsetWidth) * 100
  const y = offsetY - zoomer.getBoundingClientRect().y

  const vector = { x, y }
  for (const [key, value] of Object.entries(vector)) {
    // * ограничение, чтоб не выйти за пределы фото
    const zoomPercentage = Math.min(Math.max(0, value), 100)
    zoomer.style.setProperty(`--bg-${key}`, zoomPercentage + '%')
  }
}
/**
 * * сериалайзер для данных груза
 * @param {Object} data данные груза
 */
export const formatCargoData = (data) => {
  return {
    ...data,
    stacking_limit: data.stacking_limit ? Number(data.stacking_limit) : 0
  }
}

export const isServer = () => typeof window === 'undefined'

import React, { useEffect, useRef } from 'react'
import ModalView from '../../../ModalView'
import { BUTTON_THEME } from '../../../../const'
import styles from '../styles/index.module.scss'
import { FormattedMessage } from 'react-intl'

const RemoveModal = ({ type, name, handleClose, handleAccept, isSubmitting = false }) => {
  const ref = useRef(null)
  useEffect(() => { ref.current.setFocus() }, [])
  return (
    <div data-cy='remove-modal'>
      <ModalView.Icon.Remove />
      <ModalView.Title>
        <FormattedMessage
          id='modals.remove.title'
          defaultMessage='Вы уверены, что хотите удалить {type} <underline>{name}</underline>?'
          values={{
            type,
            name,
            underline: chunks => <span className={styles['text--underline']}>{chunks}</span>
          }}
        />
      </ModalView.Title>
      <ModalView.Buttons>
        <ModalView.Buttons.Button onClick={handleClose} theme={BUTTON_THEME.FILL_ICON.SMALL} disabled={isSubmitting}>
          <FormattedMessage
            id='cancel'
            defaultMessage='Отмена'
          />
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          data-cy='remove-modal-accept-btn'
          onClick={handleAccept}
          theme={BUTTON_THEME.DANGER_ICON.SMALL}
          disabled={isSubmitting}
          ref={ref}
        >
          <FormattedMessage
            id='delete'
            defaultMessage='Удалить'
          />
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </div>
  )
}

export default RemoveModal
